import React, { lazy, Suspense } from "react";
import Loader from "./components/ui/Loader";

const HomePage = lazy(() => import("./pages/home"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <HomePage />
    </Suspense>
  );
}

export default App;
